import {
	EMAIL_PATTERN,
	NAME_MIN_LENGTH,
	NAME_PATTERN,
	NUMBER_PATTERN,
	NAME_NAVIDAD_PATTERN,
} from './appConstants'

// React Hook Form General Validations
export const V_COUNTRY = {
	required: 'Debes seleccionar un tipo de documento',
}
export const V_TYPE_ID = {
	required: 'Debes seleccionar un tipo de documento',
}
export const V_NUMBER_ID = {
	required: 'El documento es requerido.',
	minLength: {
		value: 5,
		message: 'El documento debe tener mínimo 5 caracteres.',
	},
	maxLength: {
		value: 15,
		message: 'El documento debe tener máximo 15 caracteres.',
	},
}
export const V_EMAIL = {
	required: 'El correo es requerido.',
	minLength: {
		value: 8,
		message: 'Este campo debe contener por lo menos 8 dígitos.',
	},
	maxLength: {
		value: 50,
		message: 'Este campo debe contener máximo 50 dígitos.',
	},
	pattern: {
		value: EMAIL_PATTERN,
		message: 'El correo ingresado es inválido o un tiene espacio en blanco al final.',
	},
}
export const V_CELLPHONE = {
	required: 'Tu número celular es requerido.',
	pattern: {
		value: NUMBER_PATTERN,
		message: 'Ingresa sólo números.',
	},
	minLength: {
		value: process.env.CELL_DIG,
		message: `Un número celular debe tener ${process.env.CELL_DIG} dígitos.`,
	},
	maxLength: {
		value: process.env.CELL_DIG,
		message: `Un número celular debe tener ${process.env.CELL_DIG} dígitos.`,
	},
}
export const V_NAME = {
	required: 'El nombre es requerido.',
	pattern: {
		value: NAME_PATTERN,
		message: 'El campo contiene caracteres inválidos o un espacio en blanco al final'
	},
	minLength: {
		value: NAME_MIN_LENGTH,
		message: 'El nombre debe tener mínimo 3 caracteres.',
	},
	maxLength: {
		value: 50,
		message: 'Este campo debe contener máximo 50 dígitos.',
	},
}
export const V_NAME_NAVIDAD = {
	required: 'El nombre es requerido.',
	pattern: {
		value: NAME_NAVIDAD_PATTERN,
		message: 'El campo no permite ni caracteres especiales ni números.'
	},
	minLength: {
		value: NAME_MIN_LENGTH,
		message: 'El mínimo de caracteres es de 3.',
	},
	maxLength: {
		value: 50,
		message: 'El máximo de caracteres es de 50.',
	},
}
export const V_LASTNAME = {
	required: 'Los apellidos son requeridos.',
	pattern: {
		value: NAME_PATTERN,
		message: 'El campo contiene caracteres inválidos o un espacio en blanco al final',
	},
	minLength: {
		value: NAME_MIN_LENGTH,
		message: 'Los apellidos deben tener mínimo 3 caracteres.',
	},
	maxLength: {
		value: 50,
		message: 'Este campo debe contener máximo 50 dígitos.',
	},
}
export const V_GENDER = {
	required: 'Debes seleccionar un genero',
}
